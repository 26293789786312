/* global wpabt */
function wpabt_get_param(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  var value = '';

  if (results !== null) {
    value = decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  return value;
}

function wpabt_track(event, data = {}) {
  [
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_source',
    'utm_term',
    'gclid',
    'fbclid'
  ].forEach(function (param) {
    let value = wpabt_get_param(param);
    if (value) data[param] = value;
  });

  if (document.referrer) {
    data.referer = document.referrer;
  }

  const formData = new FormData();
  formData.append('action', 'wpabt_event');
  formData.append('event', event);

  if ( wpabt.test ) {
    formData.append('test', wpabt.test);
  }

  if ( wpabt.variant ) {
    formData.append('variant', wpabt.variant);
  }
  
  for (const key in data) {
    formData.append(key, data[key]);
  }
  
  fetch(wpabt.ajaxurl, {
    method: 'POST',
    body: formData
  });
}

document.addEventListener('DOMContentLoaded', function () {
  if (wpabt.test) {
    wpabt_track('view');

    document.addEventListener('click', function (event) {
      let target = event.target;
      while (target && target.tagName !== 'A') {
        target = target.parentNode;
      }
      
      if (target) {
        wpabt_track('click');

        if (wpabt.type === 'click') {
          wpabt_track('conversion');
        }
      }
    });

    document.addEventListener('submit', function (event) {
      if (event.target.tagName === 'FORM') {
        wpabt_track('submit');

        if ( wpabt.type === 'submit') {
          wpabt_track('conversion');
        }
      }
    });
  } else if ( wpabt.type == 'load' ) {
    wpabt.tests.forEach(function (test) {
      wpabt_track('conversion', { test: test });
    });
  }

  // document.addEventListener('change', function (event) {
  //     wpabt_track('change');
  // });

  // document.addEventListener('focus', function (event) {
  //     wpabt_track('focus');
  // });

  // document.addEventListener('blur', function (event) {
  //     wpabt_track('blur');
  // });

  // document.addEventListener('scroll', function (event) {
  //     // get scroll percentage
  //     let scroll = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
  //     // wait till scroll is done
  //     clearTimeout(this.scrollTimer);
  //     this.scrollTimer = setTimeout(function () {
  //         wpabt_track('scroll', { value: scroll * 100 });
  //     }, 250);
  // });

  // track time on page
  // let time = 0;
  // setInterval(function () {
  //     time += 1;
  //     if (time % 5 === 0) {
  //         wpabt_track('time', { value: time });
  //     }
  // }, 1000);
});